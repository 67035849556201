<script>
import appConfig from "@/app.config";

import {
  required,
  minLength
} from "vuelidate/lib/validators";

/**
 * Lock-screen-1 component
 */
export default {
  name: 'reset-password',
  page: {
    title: "Reset Password",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      typesubmit: false,
      form: {
        password: null,
        confirm_password: null
      },
      authError: null,
      IsSuccess:false,
      isAuthError: false
    }
  },
  methods: {
    resetPassword() {
      this.typesubmit = true;
      this.$v.$touch();

      let param = new FormData()
      param.append('password', this.form.password)
      param.append('confirmPassword', this.form.confirm_password)

      this.$axios.post(`/auth/reset-password/${this.$route.params.token}/staff`, param)
      .then( res => { 
          this.IsSuccess = true
      }).catch(e => {
          if (e.response) {  
              this.isAuthError = true
              this.authError = e.response.data.message
         }
      })
    }
  },
  validations: {
    form: {
      password: { required, minLength: minLength(8) },
      confirm_password: { required, minLength: minLength(8) },
    }
  },
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="bg-primary">
                <div class="text-primary text-center p-4">
                  <h5 class="text-white font-size-20">Reset Password</h5>
                  <p class="text-white-50">create your new password</p>
                  <router-link tag="a" to="/" class="logo logo-admin">
                    <img src="@/assets/images/logo.png" height="50" alt="logo" />
                  </router-link>
                </div>
              </div>

              <div class="card-body p-4">
                <div class="p-3">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                  >{{authError}}</b-alert>

                  <form class="form-horizontal mt-4" @submit.prevent="resetPassword">
                    <div class="form-group">
                      <label for="userpassword">New Password</label>
                      <input
                        v-model="form.password"
                        type="password"
                        class="form-control"
                        placeholder="Enter password"
                        :class="{ 'is-invalid': typesubmit && $v.form.password.$error }"
                      />
                      <div v-if="typesubmit && $v.form.password.$error" class="invalid-feedback">
                        <span v-if="!$v.form.password.required">This value is required.</span>
                        <span v-if="!$v.form.password.minLength">Password must be at least 8 characters.</span>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="userpassword">Confirm Password</label>
                      <input
                        v-model="form.confirm_password"
                        type="password"
                        class="form-control"
                        placeholder="Enter password"
                        :class="{ 'is-invalid': typesubmit && $v.form.confirm_password.$error }"
                      />
                      <div v-if="typesubmit && $v.form.password.$error" class="invalid-feedback">
                        <span v-if="!$v.form.password.required">This value is required.</span>
                        <span v-if="!$v.form.password.minLength">Password must be at least 8 characters.</span>
                      </div>
                    </div>
                    <div class="form-group row mb-0">
                      <div class="col-12 text-right">
                        <button class="btn btn-primary w-md" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="mt-5 text-center" v-if="IsSuccess">
              <p>
                Reset Password Success, Please
                <router-link tag="a" to="/" class="font-weight-medium text-primary">Sign In here</router-link>
              </p> 
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </div>
  </div>
</template>